<template>
    <b-form>
        <b-row>
            <b-col
                cols="12"
                :md="userType !== 'Freelancer' ? 8 : 12"
            >
                <b-card no-body>
                    <b-card-header class="border-bottom mb-2">
                        <h4 v-if="cantRespond" class="pt-1 card-title">{{i18nT(`Survey preview`)}}</h4>
                        <h4 v-else class="pt-1 card-title">{{i18nT(`Survey`)}}</h4>
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            right
                            v-if="isSurveyOnwer && iCan('surveys', 'write')"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>
                            <b-dropdown-item
                                :to="{ name: 'create-edit-survey', params: { id: surveyId } }"
                                v-if="!survey.SentAt"
                            >
                                <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteSurvey">
                <span
                    class="align-middle ml-50 text-danger"
                >
                  {{i18nT(`Delete`)}}
                </span>
                            </b-dropdown-item>


                        </b-dropdown>
                    </b-card-header>
                    <b-card-body v-if="!surveyAnswered">
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h3>{{surveyTitle}}</h3>
                                <p v-html="surveyDescription"></p>
                            </b-col>
                        </b-row>
                        <b-row
                            class="border-bottom py-2"
                            v-for="(surveyQuestion, index) in surveyQuestions"
                            :key="`survey-question-${index}`"
                        >
                            <b-col
                                cols="12"
                                v-if="surveyQuestion.Type === 1"
                            >
                                <h4>{{ index + 1 }} - {{ surveyQuestion.Label }}</h4>
                                <p>{{ surveyQuestion.Description }}</p>


                                <b-row v-if="surveyQuestion.LinesCnt === 99">
                                    <b-col cols="6" sm="6">
                                        <b-form-group
                                            :label="i18nT(`Free text answer`)"
                                            label-for="surveyDescription"

                                        >
                                            <b-form-textarea
                                                id="surveyDescription"
                                                rows="3"
                                                v-model="surveyAnswers[surveyQuestion.Id]"
                                                :disabled="cantRespond"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>


                                <b-row  v-else-if="surveyQuestion.LinesCnt === 1">
                                    <b-col cols="6" sm="6">
                                        <b-form-group
                                            :label="i18nT(`Free text answer`)"
                                            label-for="surveyDescription"

                                        >
                                            <b-form-input
                                                id="surveyDescription"
                                                rows="3"
                                                v-model="surveyAnswers[surveyQuestion.Id]"
                                                :disabled="cantRespond"
                                            />
                                        </b-form-group>

                                    </b-col>
                                </b-row>

                            </b-col>
                            <b-col
                                cols="12"
                                v-if="surveyQuestion.Type === 2"
                            >
                                <h4>{{ index + 1 }} - {{ surveyQuestion.Label }}</h4>
                                <p>{{ surveyQuestion.Description }}</p>

                                <b-form-group
                                    label=""
                                    :label-for="`survey-question-${surveyQuestion.Type}-${index}`"
                                    v-if="surveyQuestion.PossibleAnswers === 99"
                                >
                                    <b-form-checkbox-group
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :value="values"
                                        @input="onMultipleCheckboxSelected($event, surveyQuestion.Id) "
                                        :id="`survey-question-${surveyQuestion.Type}-${index}`"
                                        :disabled="cantRespond"
                                    >
                                        <b-form-checkbox
                                            v-for="surveyAnswer in surveyQuestion.AnswersData"
                                            :key="surveyAnswer.Id"
                                            :value="surveyAnswer.Id"
                                            class="d-block my-1"
                                        >{{ surveyAnswer.Label }}</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </b-form-group>

                                <b-form-group
                                    label=""
                                    :label-for="`survey-question-${surveyQuestion.Type}-${index}`"
                                    v-if="surveyQuestion.PossibleAnswers === 1"
                                >
                                    <b-form-radio-group
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :id="`survey-question-${surveyQuestion.Type}-${index}`"
                                        :disabled="cantRespond"
                                    >

                                        <b-form-radio
                                            v-for="surveyAnswer in surveyQuestion.AnswersData"
                                            :key="surveyAnswer.Id"
                                            :value="surveyAnswer.Id"
                                            class="d-block my-1"
                                            @change="clearOthers(surveyQuestion.Id)"
                                        >{{ surveyAnswer.Label }}</b-form-radio>
                                    </b-form-radio-group>

                                </b-form-group>

                                <b-row>
                                    <b-col cols="6" sm="6">
                                        <b-form-group
                                            v-if="surveyQuestion.OtherOption"
                                            :label="i18nT(`Other (add your own answer below)`)"
                                            label-for="customAnswer"
                                            class=""
                                        >
                                            <b-form-input
                                                id="customAnswer"
                                                v-model="surveyOthers[surveyQuestion.Id]"
                                                @focus="clearRadios(surveyQuestion.Id)"
                                                :disabled="cantRespond"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>


                            </b-col>
                            <b-col
                                cols="12"
                                v-if="surveyQuestion.Type === 3"
                            >
                                <h4>{{ index + 1 }} - {{ surveyQuestion.Label }}</h4>
                                <p>{{ surveyQuestion.Description }}</p>

                                <b-form-group>
                                    <b-form-radio-group
                                        v-if="surveyQuestion.Grading === 1"
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :name="`survey-question-${index}-${surveyQuestion.Grading}`"
                                        buttons
                                        button-variant="outline-primary"
                                        :disabled="cantRespond"
                                    >
                                        <b-form-radio value="yes">
                                            <span class="p-t-5 p-b-5 fs-18">🙂</span>
                                            <span class="fs-11 font-montserrat text-uppercase">{{i18nT(`Like it`)}}</span>
                                        </b-form-radio>
                                        <b-form-radio value="no">
                                            <span class="p-t-5 p-b-5 fs-18">🙁</span>
                                            <span class="fs-11 font-montserrat text-uppercase">Don't like</span>
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <b-form-radio-group
                                        v-if="surveyQuestion.Grading === 2"
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :name="`survey-question-${index}-${surveyQuestion.Grading}`"
                                        buttons
                                        button-variant="outline-primary"
                                        :disabled="cantRespond"
                                    >
                                        <b-form-radio
                                            :value="idx"
                                            v-for="idx in surveyQuestion.Steps"
                                            :key="`rank-${surveyQuestion.Grading}-${idx}`"
                                        >{{ idx }}</b-form-radio>
                                    </b-form-radio-group>
                                    <b-form-radio-group
                                        v-if="surveyQuestion.Grading === 3"
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :name="`survey-question-${index}-${surveyQuestion.Grading}`"
                                        button-variant="outline-primary"
                                        buttons
                                        :disabled="cantRespond"
                                    >
                                        <template v-for="idx in surveyQuestion.Steps">
                                            <b-form-radio
                                                :value="idx"
                                                v-if="idx === 1"
                                                :key="idx"
                                            >
                                                {{ surveyQuestion.FirstStep }}
                                            </b-form-radio>
                                            <b-form-radio
                                                :value="idx"
                                                v-else-if="idx === surveyQuestion.Steps"
                                                :key="idx"
                                            >
                                                {{ surveyQuestion.LastStep }}
                                            </b-form-radio>
                                            <b-form-radio
                                                :value="idx"
                                                v-else
                                                :key="idx"
                                            >
                                                {{ idx }}
                                            </b-form-radio>
                                        </template>
                                    </b-form-radio-group>
                                    <b-form-radio-group
                                        v-if="surveyQuestion.Grading === 4"
                                        v-model="surveyAnswers[surveyQuestion.Id]"
                                        :name="`survey-question-${index}-${surveyQuestion.Grading}`"
                                        buttons
                                        button-variant="outline-primary"
                                        :disabled="cantRespond"
                                    >
                                        <b-form-radio
                                            :value="idx"
                                            v-for="idx in surveyQuestion.Steps"
                                            :key="`rank-${surveyQuestion.Grading}-${idx}`"
                                        >{{ surveyQuestion[`Step${idx}`] }}</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <div style="flex: 1" />
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mt-0 mt-md-2 ml-1 mr-1"
                                @click.prevent="onAddNewSurveyAnswerSubmit"
                                v-if="!cantRespond"
                            >
                                {{i18nT(`Submit answers`)}}
                            </b-button>
                        </b-row>
                    </b-card-body>
                    <b-card-body v-else>
                        <h4>{{i18nT(`Invalid survey`)}}</h4>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                v-if="userType !== 'Freelancer'"
                cols="12"
                md="4"
            >
                <b-card no-body>
                    <b-card-body>
                        <h6 class="mb-2">{{i18nT(`Information`)}}</h6>
                        <b-row class="border-bottom pb-2">
                            <b-col cols="12">
                                <ul
                                    class="bg-light text-dark p-2"
                                    style="list-style: none;"
                                >
                                    <li>
                                        <feather-icon
                                            icon="CalendarIcon"
                                            size="16"
                                        /> {{i18nT(`Created`)}}: <strong>{{survey.CreatedAt | date}}</strong>
                                    </li>
                                    <li>
                                        <feather-icon
                                            icon="PieChartIcon"
                                            size="16"
                                        /> {{i18nT(`Last Updated`)}}: <strong>{{survey.UpdatedAt | date}}</strong>
                                    </li>
                                    <li v-if="survey.SentAt !== null">
                                        <feather-icon
                                            icon="SendIcon"
                                            size="16"
                                        /> Last Sent: <strong>{{survey.SentAt | date}}</strong>
                                    </li>
                                </ul>
                            </b-col>
                            <b-col cols="12">
                                <p>{{ i18nT(`Survey url`) }}</p>
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button size="sm">
                                            <feather-icon
                                                icon="ClipboardIcon"
                                                size="18"
                                                @click="onCopyUrl"
                                            />
                                        </b-button>
                                    </b-input-group-prepend>

                                    <b-form-input v-model="publicUrl"></b-form-input>
                                </b-input-group>
                                <p class="text-muted mt-1">{{i18nT(`This is the unique survey page link where people can participate. Use and share this link.`)}}</p>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <b-button
                                    block
                                    class="my-1"
                                    variant="primary"
                                    @click.prevent="goToResults"
                                    :disabled="survey.SentAt === null"
                                >
                                    <feather-icon
                                        icon="PieChartIcon"
                                        size="18"
                                    /> {{i18nT(`Show results`)}}
                                </b-button>
                                <b-button
                                    :disabled="survey.SentAt !== null"
                                    block
                                    class="my-1"
                                    variant="outline-primary"
                                    @click.prevent="onSurveySend"
                                >
                                    <span v-if="survey.SentAt !== null">{{i18nT(`Sent on`)}} {{survey.SentAt | date}}</span>
                                    <span v-else>
                    <feather-icon
                        icon="SendIcon"
                        size="18"
                    /> Send
                  </span>
                                </b-button>
                            </b-col>
                        </b-row>

                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {
    BButton,
    // BButtonGroup,
    // BAlert,
    // BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    // BFormDatepicker,
    BCardBody,
    // BFormSelect,
    VBModal
    // BTabs,
    // BTab,
    // BInputGroup,
    // BInputGroupText,
    // BListGroupItem,
} from 'bootstrap-vue'
// import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
// import draggable from "vuedraggable";
import useAuth from '@/auth/useAuth'
import { get } from 'lodash';

export default {
    components: {
        BButton,
        // BButtonGroup,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardHeader,
        BCardBody,
        // vSelect,
        BFormTextarea,
        BInputGroupPrepend,
        BInputGroup,
        // BFormDatepicker,
        // BFormSelect,
        // BAlert,
        // BBadge,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormRadio,
        BFormRadioGroup,
        BDropdown,
        BDropdownItem,
        // BTabs,
        // BTab,
        // ValidationProvider,
        // ValidationObserver,
        // BInputGroup,
        // BInputGroupText,
        // BListGroupItem,
        // draggable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    data() {
        return {
            surveyAnswers: {},
            surveyOthers: {},
            surveyNotActive: true,
            survey: {},
            surveyTitle: '',
            surveyDescription: '',
            surveyQuestions: [],
            values: [],
            publicUrl: '',
            surveyAnswered: false
        }
    },
    watch: {
        surveyNotActive() {
            this.onSurveyActivate()
        },
        sele: {
            handler(newAnswers) {
                console.log("New answers", newAnswers);
            },
            deep: true
        }
    },
    computed: {
        cantRespond(){
            return !(this.userType === 'Freelancer');
        },
        surveyId() {
            return router.currentRoute.params.id
        },
        isSurveyOnwer() {
            return this.survey.OwnerId === useAuth.getProfile().Id
        },
        userType() {
            return useAuth.getUserType()
        },
        currentSoftware() {
            return useAuth.getCurrentSoftware()
        }
    },
    created() {
        this.getSurveyDetail()
    },
    methods: {
        clearRadios(anwserId) {

            this.surveyAnswers[anwserId] = null;
            this.surveyAnswers = {...this.surveyAnswers};
        },

        clearOthers(anwserId) {
            this.surveyOthers[anwserId] = null;
            this.surveyOthers = {...this.surveyOthers};
        },

        deleteSurvey() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1"
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`surveys?id=${this.surveyId}`).then(() => {
                        this.$swal({
                            icon: "success",
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The survey has been deleted.`),
                            customClass: {
                                confirmButton: "btn btn-success"
                            }
                        });
                        this.$router.push({ name: 'surveys' })
                    });
                }
            });
        },
        getSurveyDetail() {
            const id = router.currentRoute.params.id
            if (id !== 'new') {
                this.$http.get(`surveys?id=${id}`).then(({ data }) => {
                    const survey = data.data.survey
                    this.survey = survey
                    this.surveyNotActive = survey.PublicationStatus !== 'DEACTIVATED'
                    this.surveyTitle = survey.Label
                    this.surveyDescription = survey.Description
                    this.surveyQuestions = survey.Questions
                    this.surveyQuestions.forEach(q => {
                        if (q.Type === 2 && q.PossibleAnswers === 99)
                            this.surveyAnswers[q.Id] = []
                        else this.surveyAnswers[q.Id] = ''

                        if (q.Type === 2 && q.OtherOption === 1)
                            this.surveyOthers[q.Id] = ''
                    })
                    this.publicUrl = window.location.href
                }).catch(err => {
                    const code = get(err, 'response.data.code')
                    if (code === 403) this.surveyAnswered = true
                    else
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                })
            }
        },
        onCopyUrl() {
            navigator.clipboard.writeText(`${this.publicUrl}`).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'URL copied',
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
            })
        },
        goToResults() {
            router.push({
                name: this.currentSoftware == 'em' ? 'em-result-survey' : 'result-survey',
                params: { id: this.survey.Id }
            })
        },
        onMultipleCheckboxSelected(e, id) {
            this.surveyAnswers[id] = e.map(q => ({ [q]: 1 }))
        },
        onSurveyActivate() {
            const id = router.currentRoute.params.id
            this.$http
                .post(`surveys/activate?survey_id=${id}`)
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(response),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onSurveySend() {
            const id = router.currentRoute.params.id
            this.$http
                .post(`surveys/send?survey_id=${id}`)
                .then(response => {
                    this.getSurveyDetail()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(response),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onAddNewSurveyAnswerSubmit() {
            const id = router.currentRoute.params.id

            const formData = new FormData()
            formData.append('answers', JSON.stringify(this.surveyAnswers))
            formData.append('other', JSON.stringify(this.surveyOthers))

            this.$http
                .post(`surveys/submit?survey_id=${id}`, formData)
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(response),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.goToResults()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
    }
}
</script>

<style lang="scss">
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}

.text-muted label {
    color: #b9b9c3 !important;
    font-size: 1em;
}

</style>
